import { ChangeEvent, useEffect, useState } from 'react';
import { Button, ListActionsProps, useResourceContext, useTranslate } from 'react-admin';
import { Button as MuiButton, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';

import ResourceFieldsSelect from '@components/shared/ResourceFieldsSelect';

import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import { useListColumnContext } from '@js/context/ListColumnsContext';
import { useListGuesserControllerContext } from '@components/list/ListGuesser';

interface Props extends ListActionsProps {
    translateResource?: string;
}

const VisibleColumnsButton = ({ translateResource, ...props }: Props) => {
    const { selectedColumns, fields } = useListGuesserControllerContext();
    const [selected, setSelected] = useState(selectedColumns);
    const [open, setOpen] = useState(false);
    const isSmallScreen = useIsSmallScreen();
    const resource = useResourceContext(props);
    const translate = useTranslate();
    const { saveResourceColumns } = useListColumnContext();

    useEffect(() => {
        if (selected.length === 0 && selectedColumns.length > 0) {
            setSelected(selectedColumns);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedColumns]);

    if (isSmallScreen) {
        return null;
    }

    const handleClose = () => {
        const selectionChangedAndValid =
            selected.length > 0 &&
            (selected.length !== selectedColumns.length ||
                selected.filter((columnName) => !selectedColumns.includes(columnName)).length > 0);

        if (selectionChangedAndValid) {
            saveResourceColumns(resource, [...selected]);
        } else {
            // Reset local selection state if nothing changed or nothing selected
            setSelected(selectedColumns);
        }

        setOpen(false);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;

        if (event.target.checked) {
            setSelected((selected) => [...selected, name]);
        } else {
            setSelected((selected) => selected.filter((item) => item !== name));
        }
    };

    return (
        <>
            <Button label="app.action.visible_columns" onClick={() => setOpen(true)}>
                <ViewColumnIcon />
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth>
                <DialogTitle>{translate('app.action.visible_columns')}</DialogTitle>
                <DialogContent>
                    <ResourceFieldsSelect
                        fields={fields}
                        checked={(field) => selected.includes(field.name)}
                        onChange={handleChange}
                        translateResource={translateResource}
                    />
                </DialogContent>
                <DialogActions>
                    <MuiButton onClick={handleClose} color="primary" disabled={selected.length === 0}>
                        {translate('ra.action.close')}
                    </MuiButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default VisibleColumnsButton;
