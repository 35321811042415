import { useEffect, useState } from 'react';
import { Box, LinearProgress, Toolbar as MuiToolbar } from '@material-ui/core';
import { Button, CardContentInner } from 'react-admin';
import CancelIcon from '@material-ui/icons/Cancel';
import ContentSave from '@material-ui/icons/Save';

import LoadingButton from '@components/button/LoadingButton';
import ImportErrors from '@components/routes/ImportErrors';

import { post } from '@js/request/apiRequest';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';

import { State } from './ExternalOrdersImport';

type Props = {
    state: State;
    onSubmit: () => void;
    onGoBack: () => void;
};

type ValidationError = { message: string; severity: 'warning' | 'error' };

type ValidationPayload = {
    errors: ValidationError[];
};

const ConfirmStep = ({ state, onSubmit, onGoBack }: Props) => {
    const [errors, setErrors] = useState<ValidationPayload['errors']>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const notifyError = useNotifyHttpError();
    const isLoading = errors === undefined;

    useEffect(() => {
        if (errors) return;

        const formData = new FormData();

        const { file, ...preselected } = state.upload ?? {};
        formData.append('file', file?.rawFile ?? '');
        formData.append('mapping', JSON.stringify(state.mapping));
        formData.append('preselected', JSON.stringify(preselected));

        post<ValidationPayload>('/api/errands/external_sales_validate', {
            body: formData,
        })
            .then((response) => {
                setErrors(response.errors);
            })
            .catch((error) => {
                notifyError(error);
            });
    }, [errors, notifyError, state.mapping, state.upload]);

    const handleConfirm = () => {
        setIsSubmitting(true);
        onSubmit();
    };

    return (
        <>
            <CardContentInner>
                {errors ? (
                    <ImportErrors errors={errors} />
                ) : (
                    <Box my={2}>
                        <LinearProgress />
                    </Box>
                )}
            </CardContentInner>
            <MuiToolbar>
                <Box display="flex" gridGap={10}>
                    <Button size="medium" color="default" label="ra.action.cancel" onClick={onGoBack}>
                        <CancelIcon />
                    </Button>
                    <LoadingButton
                        icon={<ContentSave />}
                        onClick={handleConfirm}
                        loading={isSubmitting}
                        disabled={isLoading || isSubmitting}
                        label="app.action.import"
                        size="medium"
                    />
                </Box>
            </MuiToolbar>
        </>
    );
};

export default ConfirmStep;
