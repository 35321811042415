import { FC, useState } from 'react';
import { LinearProgress, useTranslate } from 'react-admin';
import { Box, Button, ButtonGroup, Typography } from '@material-ui/core';

import DatagridLoading from '@components/list/DatagridLoading';
import List from '../List';

import useFetchGet from '@js/hooks/useFetchGet';
import useFormatNumber from '@js/hooks/useFormatNumber';
import { Saving, SavingType } from '@js/interfaces/Saving';

import { FilterValues } from './Filters';

type Props = {
    filters?: FilterValues;
};

type SavingsDetailedPayload = {
    savings_count: Record<SavingType, Record<Saving, number>>;
};

const SavingsCount: FC<Props> = ({ filters }) => {
    const [showType, setShowType] = useState<SavingType>(SavingType.IncludePurchasePrice);
    const { data, loading } = useFetchGet<SavingsDetailedPayload>('/api/dashboard/savings_count', filters);
    const translate = useTranslate();
    const formatNumber = useFormatNumber();

    if (loading && !data) return <DatagridLoading nbFakeLines={4} />;
    if (!data) return null;

    const getItems = (type: SavingType) => {
        const savings = data.savings_count[type] ?? {};

        return (Object.entries(savings) as Array<[Saving, number]>).map(([saving, value]) => ({
            label: translate(`app.co2.savings.${saving}`),
            value: formatNumber(value),
        }));
    };

    return (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">{translate('app.action.co2')}</Typography>
                {loading && <LinearProgress />}
            </Box>
            <Box my={1}>
                <ButtonGroup variant="contained" color="primary" size="small">
                    {Object.values(SavingType).map((type) => (
                        <Button
                            key={type}
                            variant={type === showType ? 'outlined' : 'contained'}
                            onClick={() => setShowType(type)}
                        >
                            {translate(`app.co2.${type}`)}
                        </Button>
                    ))}
                </ButtonGroup>
            </Box>
            <List items={getItems(showType)} />
        </>
    );
};

export default SavingsCount;
