import { CardContentInner, FileField, FileInput, required, useGetResourceLabel, useTranslate } from 'react-admin';
import { Typography } from '@material-ui/core';
import { Form } from 'react-final-form';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import Toolbar from '@components/routes/Toolbar';
import AutoCompleteGuesserInput from '@components/input/AutoCompleteGuesserInput';
import CurrencySelectInput from '@components/input/CurrencySelectInput';

import { State } from './ExternalOrdersImport';

type Props = {
    onSubmit: (values: State['upload']) => void;
    onGoBack: () => void;
};

const UploadStep = ({ onSubmit, onGoBack }: Props) => {
    const translate = useTranslate();
    const getResourceLabel = useGetResourceLabel();

    return (
        <Form onSubmit={onSubmit}>
            {({ handleSubmit, valid, submitting }) => (
                <>
                    <CardContentInner>
                        <Typography variant="h6" gutterBottom>
                            {translate('app.form.helperText.price_per_item_warning')}
                        </Typography>
                        <FileInput
                            source="file"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            validate={required()}
                        >
                            <FileField source="src" title="title" />
                        </FileInput>
                        <CurrencySelectInput source="currency" validate={required()} />
                        <AutoCompleteGuesserInput
                            label={getResourceLabel('sale_channels')}
                            reference="sale_channels"
                            source="saleChannel"
                            validate={required()}
                            fullWidth
                        />
                    </CardContentInner>
                    <Toolbar
                        cancel={{ onClick: onGoBack }}
                        submit={{
                            onSubmit: handleSubmit,
                            disabled: !valid,
                            saving: submitting,
                            label: 'ra.navigation.next',
                            icon: NavigateNextIcon,
                        }}
                    />
                </>
            )}
        </Form>
    );
};

export default UploadStep;
