import {
    AutocompleteInput,
    Button as RaButton,
    ReferenceInput,
    required,
    useDeleteWithConfirmController,
    useRecordContext,
    useResourceContext,
    useTranslate,
} from 'react-admin';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { get } from 'lodash';
import { Form } from 'react-final-form';

import AlertError from '@material-ui/icons/ErrorOutline';
import ActionCheck from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';

import IconButton from '@components/button/IconButton';
import LoadingButton from '@components/button/LoadingButton';

import useResourceFieldName from '@js/hooks/useResourceFieldName';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import { useEntrypoint } from '@js/context/AppConfigContext';
import { useDeleteIconStyles } from '@components/button/DeleteIconButton';

import { post } from '@js/request/apiRequest';
import { Iri } from '@js/interfaces/ApiRecord';
import { SaleChannel } from '@js/interfaces/SaleChannel';

const MigrateDeleteIconButton = (props: { record?: SaleChannel; resource?: string }) => {
    const resource = useResourceContext(props);
    const record = useRecordContext(props);
    const classes = useDeleteIconStyles();
    const translate = useTranslate();
    const fieldName = useResourceFieldName();
    const notifyError = useNotifyHttpError();
    const entrypoint = useEntrypoint();

    const {
        open,
        loading: isDeleting,
        handleDialogOpen,
        handleDialogClose,
        handleDelete,
    } = useDeleteWithConfirmController({
        record,
        redirect: false,
        mutationMode: 'pessimistic',
        resource,
    });

    if (!record) return null;

    const disabledReason =
        record.type !== null ? 'resources.sale_channels.delete_disable_reason.is_system_channel' : null;

    const handleConfirm = async (data: { to: Iri }) => {
        try {
            await post(`${entrypoint}/${resource}/migrate`, {
                body: JSON.stringify({
                    from: record.id,
                    to: data.to,
                }),
            });

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            handleDelete({ stopPropagation: () => {} }); // Mock event and call delete
        } catch (e) {
            notifyError(e);
        }
    };

    return (
        <>
            <div className={classes.deleteButton}>
                <IconButton label="ra.action.delete" onClick={handleDialogOpen} disabledReason={disabledReason}>
                    <DeleteIcon />
                </IconButton>
            </div>
            <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="sm">
                <DialogTitle>
                    {translate('ra.message.delete_title', {
                        name: `"${get(record, fieldName)}"`,
                        id: record.id.toString().split('/').pop(),
                    })}
                </DialogTitle>
                <Form onSubmit={handleConfirm}>
                    {({ errors, pristine, handleSubmit, submitting }) => {
                        const hasErrors = typeof errors === 'object' && Object.keys(errors).length > 0;

                        return (
                            <>
                                <DialogContent>
                                    <ReferenceInput
                                        resource={resource}
                                        reference="sale_channels"
                                        source="to"
                                        sort={{ field: fieldName, order: 'ASC' }}
                                        validate={required()}
                                        fullWidth
                                        resettable
                                    >
                                        <AutocompleteInput source={fieldName} matchSuggestion={() => true} />
                                    </ReferenceInput>
                                </DialogContent>
                                <DialogActions>
                                    <RaButton
                                        size="small"
                                        color="default"
                                        label="ra.action.cancel"
                                        onClick={handleDialogClose}
                                    >
                                        <AlertError />
                                    </RaButton>
                                    <LoadingButton
                                        disabled={pristine || hasErrors}
                                        loading={submitting || isDeleting}
                                        onClick={handleSubmit}
                                        size="small"
                                        label="ra.action.confirm"
                                        icon={<ActionCheck />}
                                    />
                                </DialogActions>
                            </>
                        );
                    }}
                </Form>
            </Dialog>
        </>
    );
};

export default MigrateDeleteIconButton;
