import { useRecordContext } from 'react-admin';
import { Box, IconButton, Tooltip, Typography } from '@material-ui/core';
import { get } from 'lodash';
import InfoIcon from '@material-ui/icons/Info';

import { useFormatPrice } from '@js/hooks/useFormatPrice';
import { GiabsSale } from '@js/interfaces/GiabsSale';

type Props = {
    source?: string;
    currencyField?: string;
    record?: GiabsSale;
    resource?: string;
    sortable?: boolean;
};

const GiabsSalePriceField = ({ source, currencyField, ...props }: Props) => {
    const record = useRecordContext(props);
    const currency = get(record, currencyField!);

    const formatPrice = useFormatPrice(currency);
    const formattedSalesPrice = formatPrice(get(record, source!));

    if (!record) return null;

    const salesPriceComponents = [
        record.serviceFeePrice,
        record.mobileCirclePrice,
        record.deductibleArticlePrice,
        record.differencePrice,
    ].filter((price): price is number => price !== null);

    let saleCalculationFormula = '';

    for (const price of salesPriceComponents) {
        const formattedPrice = formatPrice(price);

        if (saleCalculationFormula.length === 0) {
            saleCalculationFormula += formattedPrice;
            continue;
        }

        saleCalculationFormula += price < 0 ? ` - ${formattedPrice.substring(1)}` : ` + ${formattedPrice}`;
    }
    saleCalculationFormula += ` = ${formattedSalesPrice}`;

    return (
        <Box display="flex" alignItems="center">
            <Typography component="span" variant="body2">
                {formattedSalesPrice}
            </Typography>
            <Tooltip title={saleCalculationFormula}>
                <IconButton size="small" color="secondary" onClick={(e) => e.preventDefault()}>
                    <InfoIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Box>
    );
};

GiabsSalePriceField.defaultProps = {
    source: 'salesPrice',
    currencyField: 'currency',
    sortable: false,
};

export default GiabsSalePriceField;
