import { Datagrid, DateField, Record as RaRecord, ReferenceManyField, TextField } from 'react-admin';

import RecordPriceField from '@components/field/RecordPriceField';
import AutoHidePagination from '@components/list/AutoHidePagination';
import AlertEmptyResource from '@components/list/AlertEmptyResource';
import PassthroughProps from '@components/PassthroughProps';
import GiabsSalePriceField from '@components/resource/sales/GiabsSalePriceField';

import useSalesContextFilter from '@components/resource/reclamation/useSalesContextFilter';

type Props = {
    record?: RaRecord;
    resource?: string;
    basePath?: string;
};

const GiabsSales = (props: Props) => {
    const { warning, filter } = useSalesContextFilter();

    return (
        <ReferenceManyField
            {...props}
            target="reclamation"
            reference="giabs_sales"
            sort={{ field: 'soldAt', order: 'DESC' }}
            pagination={<AutoHidePagination />}
            filter={filter}
        >
            <PassthroughProps>
                {(props) => (
                    <>
                        {warning}
                        <Datagrid {...props} empty={<AlertEmptyResource mb={2} />}>
                            <DateField source="soldAt" showTime />
                            <GiabsSalePriceField />
                            <TextField source="quantity" sortable={false} />
                            <RecordPriceField source="serviceFeePrice" currencyField="currency" sortable={false} />
                            <RecordPriceField source="mobileCirclePrice" currencyField="currency" sortable={false} />
                            <RecordPriceField
                                source="deductibleArticlePrice"
                                currencyField="currency"
                                sortable={false}
                            />
                            <RecordPriceField source="differencePrice" currencyField="currency" sortable={false} />
                        </Datagrid>
                    </>
                )}
            </PassthroughProps>
        </ReferenceManyField>
    );
};

export default GiabsSales;
