import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    AutocompleteInput,
    Button as RaButton,
    maxValue,
    minValue,
    NumberInput,
    Record as RaRecord,
    RecordContextProvider,
    RecordMap,
    ReferenceInput,
    required,
    useGetResourceLabel,
    useNotify,
    useRecordContext,
    useRefresh,
    useResourceContext,
    useTranslate,
} from 'react-admin';
import {
    Box,
    Button,
    Dialog as MuiDialog,
    DialogActions,
    DialogContent as MuiDialogContent,
    DialogTitle,
    Divider,
    Grid,
    makeStyles,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import { get } from 'lodash';
import { Field } from '@api-platform/api-doc-parser';
import { ApiPlatformAdminState } from '@api-platform/admin';

import AddIcon from '@material-ui/icons/Add';
import ReturnIcon from '@material-ui/icons/FlipCameraAndroid';
import CloseIcon from '@material-ui/icons/Close';

import LoadingButton from '@components/button/LoadingButton';
import IconButton from '@components/button/IconButton';
import HierarchicalAutocompleteSelectInput from '@components/form/HierarchicalAutocompleteSelectInput';
import InputGuesser from '@components/form/InputGuesser';
import AutoCompleteGuesserInput from '@components/input/AutoCompleteGuesserInput';
import ReferenceRecursiveSelectInput from '@components/input/ReferenceRecursiveSelectInput';
import AddressTypeInput from '@components/resource/address/AddressTypeInput';

import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import useFormFields from '@js/hooks/useFormFields';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import useResourceFieldName from '@js/hooks/useResourceFieldName';

import { cloneErrand, cloneReclamation } from '@js/utility/cloneUtil';
import { post } from '@js/request/apiRequest';

import { Errand } from '@js/interfaces/errand';
import { Reclamation } from '@js/interfaces/reclamation';
import { Iri } from '@js/interfaces/ApiRecord';

type Props = {
    record?: Errand;
    resource?: string;
};

const ReturnedItemButton = (props: Props) => {
    const record = useRecordContext(props);
    const resource = useResourceContext(props);
    const [open, setOpen] = useState(false);
    const isSmallScreen = useIsSmallScreen();
    const classes = useStyles();

    if (resource !== 'errands') throw new Error('ReturnedItemButton can only be used inside ErrandList');
    if (!record) return null;

    const handleClose = () => setOpen(false);

    return (
        <>
            <IconButton label="app.action.returned_item" onClick={() => setOpen(true)}>
                <ReturnIcon />
            </IconButton>
            <MuiDialog
                open={open}
                onClose={handleClose}
                fullScreen={isSmallScreen}
                maxWidth="md"
                fullWidth
                classes={classes}
            >
                <DialogContent errand={record} onClose={handleClose} />
            </MuiDialog>
        </>
    );
};

type CustomerSelectMode = 'select' | 'create';

const DialogContent = ({ errand, onClose }: { errand: Errand; onClose: () => void }) => {
    const translate = useTranslate();
    const getResourceLabel = useGetResourceLabel();
    const getFieldLabel = useTranslateResourceField('reclamations');
    const [customerMode, setCustomerMode] = useState<CustomerSelectMode>('select');
    const selectReclamation = useSelectReclamation();

    const handleSubmit = useHandleSubmit(errand, onClose, selectReclamation);

    return (
        <>
            <DialogTitle>{translate('app.action.returned_item')}</DialogTitle>
            <RecordContextProvider value={{}}>
                <Form onSubmit={handleSubmit} destroyOnUnregister>
                    {(formProps) => {
                        return (
                            <>
                                <MuiDialogContent>
                                    <AutoCompleteGuesserInput
                                        reference="reclamations"
                                        source="reclamations"
                                        label={getResourceLabel('reclamations')}
                                        validate={required()}
                                        filter={{
                                            errand: errand.id,
                                            'exists[child]': false,
                                        }}
                                        fullWidth
                                        multiple
                                    />
                                    {Array.isArray(formProps.values.reclamations) && (
                                        <Box display="flex" gridGap={8}>
                                            {formProps.values.reclamations.map((iri) => {
                                                return (
                                                    <QuantityInput
                                                        key={iri}
                                                        label={getFieldLabel('quantity')}
                                                        source={`quantity[${iri}]`}
                                                        record={selectReclamation(iri)}
                                                    />
                                                );
                                            })}
                                        </Box>
                                    )}
                                    <ReferenceRecursiveSelectInput
                                        label={getFieldLabel('status')}
                                        source="status"
                                        reference="statuses"
                                        validate={required()}
                                        fullWidth
                                    />
                                    <HierarchicalAutocompleteSelectInput
                                        reference="stores"
                                        label={getFieldLabel('store')}
                                        source="store"
                                        validate={required()}
                                        fullWidth
                                    />
                                    <CustomerInput
                                        key={customerMode}
                                        label={getFieldLabel('customer', 'errands')}
                                        source="customer"
                                        mode={customerMode}
                                        onModeChange={setCustomerMode}
                                    />
                                </MuiDialogContent>
                                <DialogActions>
                                    <Button onClick={onClose}>{translate('ra.action.cancel')}</Button>
                                    <LoadingButton
                                        label="ra.action.confirm"
                                        loading={formProps.submitting}
                                        icon={<ReturnIcon />}
                                        onClick={formProps.handleSubmit}
                                    />
                                </DialogActions>
                            </>
                        );
                    }}
                </Form>
            </RecordContextProvider>
        </>
    );
};

const QuantityInput = (props: { source: string; label: string; record: Reclamation }) => {
    const record = useRecordContext(props);
    const fieldName = useResourceFieldName({ resource: 'reclamations' });
    const translate = useTranslate();

    return (
        <NumberInput
            {...props}
            step="1"
            validate={[required(), minValue(1), maxValue(record.soldQuantity || 1)]}
            helperText={`${get(record, fieldName)}: ${translate('app.label.sold')} - ${record.soldQuantity}`}
        />
    );
};

const CustomerInput = ({
    label,
    source,
    mode,
    onModeChange,
}: {
    label: string;
    source: string;
    mode: CustomerSelectMode;
    onModeChange: (mode: CustomerSelectMode) => void;
}) => {
    if (mode === 'create') {
        return <CustomerFormFields source={source} onCancel={() => onModeChange('select')} />;
    }

    return (
        <Box display="flex" alignItems="center">
            <ReferenceInput
                label={label}
                source={source}
                reference="customers"
                validate={required()}
                sort={{
                    field: 'fullName',
                    order: 'ASC',
                }}
                fullWidth
            >
                <AutocompleteInput optionText="optionText" />
            </ReferenceInput>
            <Box mb="20px">
                <RaButton onClick={() => onModeChange('create')} label="ra.action.create">
                    <AddIcon />
                </RaButton>
            </Box>
        </Box>
    );
};

const CustomerFormFields = ({ source, onCancel }: { source: string; onCancel: () => void }) => {
    const [firstCustomerField, ...otherCustomerFields] = useFormFields({ resource: 'customers' }, ['serialNumber']);

    const [addAddress, setAddAddress] = useState(false);
    const addressFields = useFormFields({ resource: 'addresses' });

    const getFieldLabel = useTranslateResourceField('customers');
    const getResourceLabel = useGetResourceLabel();
    const translate = useTranslate();

    const getAddressInputElement = (field: Field) => {
        const finalSource = `${source}.address.${field.name}`;
        const label = getFieldLabel(field.name, 'addresses');

        switch (field.name) {
            case 'type':
                return <AddressTypeInput label={label} source={finalSource} fullWidth />;
            default:
                return <InputGuesser label={label} source={finalSource} field={field} fullWidth />;
        }
    };

    return (
        <>
            <Box display="flex" alignItems="center">
                <InputGuesser
                    source={`${source}.${firstCustomerField.name}`}
                    label={getFieldLabel(firstCustomerField.name)}
                    field={firstCustomerField}
                    fullWidth
                />
                <Box mb="20px">
                    <RaButton onClick={onCancel} label="ra.action.cancel">
                        <CloseIcon />
                    </RaButton>
                </Box>
            </Box>
            <Grid container spacing={1}>
                {otherCustomerFields.map((field) => {
                    return (
                        <Grid key={field.name} item xs={12} sm={4}>
                            <InputGuesser
                                source={`${source}.${field.name}`}
                                label={getFieldLabel(field.name)}
                                field={field}
                                fullWidth
                            />
                        </Grid>
                    );
                })}
            </Grid>
            <Divider />
            <br />
            {addAddress ? (
                <Grid container spacing={1}>
                    {addressFields.map((field) => {
                        return (
                            <Grid key={field.name} item xs={12} sm={4}>
                                {getAddressInputElement(field)}
                            </Grid>
                        );
                    })}
                </Grid>
            ) : (
                <RaButton
                    onClick={() => setAddAddress(true)}
                    label={translate('ra.action.create_item', { item: getResourceLabel('addresses', 1) })}
                >
                    <AddIcon />
                </RaButton>
            )}
        </>
    );
};

const useSelectReclamation = () => {
    const storeOfReclamations = useSelector<ApiPlatformAdminState, RecordMap<RaRecord>>(
        (data) => data.admin.resources['reclamations'].data,
    );

    return (iri: Iri) => {
        // This should never happen, because the reclamation was selected from the list of available reclamations.
        if (!storeOfReclamations[iri]) throw new Error(`Reclamation with id ${iri} not found`);

        return storeOfReclamations[iri] as Reclamation;
    };
};

type FormValues = {
    customer: Iri | Record<string, any>;
    store: string;
    reclamations: Iri[];
    quantity: Record<Iri, number>;
    status: Iri;
};

const useHandleSubmit = (errand: Errand, closeDialog: () => void, selectReclamation: (iri: Iri) => Reclamation) => {
    const notify = useNotify();
    const notifyError = useNotifyHttpError();
    const refresh = useRefresh();

    return async ({ reclamations, quantity, ...data }: FormValues) => {
        const reclamationClones = reclamations.map((iri) => {
            const reclamation = selectReclamation(iri);

            return {
                ...cloneReclamation(reclamation),
                parent: reclamation.id,
                quantity: quantity[iri],
            };
        });
        const errandClone = cloneErrand(errand);

        try {
            await post<Errand>(`${errand.id}/returned_item`, {
                body: JSON.stringify({
                    ...data,
                    errandClone,
                    reclamationClones,
                }),
            });

            notify('ra.notification.created', {
                type: 'info',
                messageArgs: { smart_count: 1 },
            });

            closeDialog();
            refresh();
        } catch (e) {
            notifyError(e);
        }
    };
};

const useStyles = makeStyles({
    container: {
        alignItems: 'flex-start',
    },
});

export default ReturnedItemButton;
