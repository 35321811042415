import { useState } from 'react';
import {
    Button,
    LinearProgress,
    useGetResourceLabel,
    useListContext,
    useNotify,
    useResourceContext,
    useTranslate,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';
import ActionCheck from '@material-ui/icons/CheckCircle';
import AlertError from '@material-ui/icons/ErrorOutline';
import SettingsIcon from '@material-ui/icons/Settings';

import LoadingButton from '@components/button/LoadingButton';

import { useAppConfigContext, useEntrypoint } from '@js/context/AppConfigContext';
import { createListURL } from '@js/utility/hydraRequestUtility';
import useResourceExportFields, { ExportField } from '@js/hooks/useResourceExportFields';
import useFormatNumber from '@js/hooks/useFormatNumber';
import { get } from '@js/request/apiRequest';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';

const ErrandsConfigurableExportButton = (props: { resource?: string }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [loadingExport, setLoadingExport] = useState(false);

    const entrypoint = useEntrypoint();
    const { total, selectedIds, loading: listLoading, currentSort, filterValues } = useListContext();
    const resource = useResourceContext(props);
    const { exportMaxItems } = useAppConfigContext();
    const notify = useNotify();
    const notifyError = useNotifyHttpError();

    const countExport = selectedIds.length || total;

    const handleConfirmExport: ConfirmExportHandler = (selectedErrandFields, selectedReclamationFields) => {
        const url = createListURL(new URL(`${entrypoint}/${resource}/export`), {
            sort: currentSort,
            filter: {
                'fields[errands]': selectedErrandFields.join(','),
                'fields[reclamations]': selectedReclamationFields.join(','),
                ...filterValues,
                ...(selectedIds.length && { id: selectedIds }),
            },
        });

        setLoadingExport(true);

        get(url.toString())
            .then(() => {
                setOpenDialog(false);
                notify('app.message.export_enqueued');
            })
            .catch((error) => {
                notifyError(error);
            })
            .finally(() => setLoadingExport(false));
    };

    return (
        <>
            <Button onClick={() => setOpenDialog(true)} label="ra.action.export" disabled={total === 0 || listLoading}>
                <DownloadIcon />
            </Button>
            {openDialog && (
                <ConfigureOptionsDialog
                    resource={resource}
                    countExport={countExport}
                    exportMaxItems={exportMaxItems}
                    onClose={() => setOpenDialog(false)}
                    onConfirmExport={handleConfirmExport}
                    loadingExport={loadingExport}
                />
            )}
        </>
    );
};

type ConfirmExportHandler = (selectedErrandFields: string[], selectedReclamationFields: string[]) => void;

const ConfigureOptionsDialog = ({
    resource,
    countExport,
    onClose,
    onConfirmExport,
    exportMaxItems,
    loadingExport,
}: {
    resource: string;
    onClose: () => void;
    countExport: number;
    onConfirmExport: ConfirmExportHandler;
    exportMaxItems: number;
    loadingExport: boolean;
}) => {
    const translate = useTranslate();
    const formatNumber = useFormatNumber();

    const errandExportController = useResourceExportFields('errands');
    const reclamationExportController = useResourceExportFields('reclamations');

    const loading = errandExportController.loading || reclamationExportController.loading;

    return (
        <Dialog open={true} onClose={onClose}>
            <DialogTitle>{translate('ra.action.confirm')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {countExport > exportMaxItems
                        ? translate('app.message.bulk_export_over_limit_warning', {
                              total: formatNumber(countExport),
                              limit: formatNumber(exportMaxItems),
                          })
                        : translate('app.message.bulk_export_warning', { total: formatNumber(countExport) })}
                </DialogContentText>
                {!loading ? (
                    <>
                        <SelectedFieldsList exportController={errandExportController} />
                        <SelectedFieldsList exportController={reclamationExportController} />
                    </>
                ) : (
                    <LinearProgress />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} label="ra.action.cancel" color="default">
                    <AlertError />
                </Button>
                <Button
                    component={Link}
                    to={`/${resource}/configure_export`}
                    label="app.action.configure"
                    color="default"
                    disabled={loading || loadingExport}
                >
                    <SettingsIcon />
                </Button>
                <LoadingButton
                    label="ra.action.confirm"
                    color="primary"
                    variant="text"
                    loading={loadingExport || loading}
                    icon={<ActionCheck />}
                    onClick={() =>
                        onConfirmExport(
                            errandExportController.selectedFields,
                            reclamationExportController.selectedFields,
                        )
                    }
                />
            </DialogActions>
        </Dialog>
    );
};

const SelectedFieldsList = ({
    exportController: { selectedFields, fields },
}: {
    exportController: ReturnType<typeof useResourceExportFields>;
}) => {
    const getResourceLabel = useGetResourceLabel();

    const mappedFields: Record<string, ExportField> = {};
    for (const field of fields) {
        mappedFields[field.name] = field;
    }

    return (
        <>
            <Typography variant="subtitle2">{getResourceLabel('errands')}:</Typography>
            <Typography variant="body2" gutterBottom>
                {selectedFields.map((field) => mappedFields[field]?.label ?? field).join(', ')}
            </Typography>
        </>
    );
};

export default ErrandsConfigurableExportButton;
