import { FC } from 'react';
import {
    Edit,
    EditProps,
    FormTab,
    ImageField as RaImageField,
    ImageFieldProps,
    ImageInput,
    NumberInput,
    Record as RaRecord,
    required,
    SaveButton as RaSaveButton,
    TabbedForm,
    Toolbar as RaToolbar,
    ToolbarProps,
    useGetResourceLabel,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import { useFormState } from 'react-final-form';

import InverseBooleanInput from '@components/input/InverseBooleanInput';
import HexInputColorPickerInput from '@components/input/HexInputColorPickerInput';

import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import ThemePaletteInput from '@components/resource/user_groups/input/ThemePaletteInput';
import { post } from '@js/request/apiRequest';
import { useUpdateSettings } from '@js/context/AppConfigContext';

const transform =
    (updateLogo: (logo: string | null) => void) =>
    async ({ logoFile, updatedAt, ...data }: RaRecord) => {
        if ((logoFile && logoFile.rawFile instanceof File) || logoFile === null) {
            const formData = new FormData();
            formData.append('logoFile', logoFile && logoFile.rawFile instanceof File ? logoFile.rawFile : null);

            try {
                const response = await post<{ asset: string | null; updatedAt: string }>(`${data.id}/upload_logo`, {
                    method: 'POST',
                    body: formData,
                });

                logoFile = response.asset;
                updatedAt = response.updatedAt;
            } catch (error) {
                console.error(error);
                logoFile = null;
            } finally {
                updateLogo(logoFile);
            }
        }

        return { ...data, logoFile, updatedAt };
    };

const SettingsEdit: FC<EditProps> = (props) => {
    const translate = useTranslate();
    const getResourceLabel = useGetResourceLabel();
    const updateSettings = useUpdateSettings();

    const updateLogo = (logo: string | null) => {
        updateSettings({ logoFile: logo });
    };

    return (
        <Edit {...props} title={getResourceLabel('settings', 1)} transform={transform(updateLogo)}>
            <TabbedForm redirect={false} toolbar={<Toolbar />}>
                <FormTab label={translate('resources.settings.tab.general')}>
                    <InverseBooleanInput source="features.disableSello" />
                    <InverseBooleanInput source="features.disableSplitProducts" />
                    <InverseBooleanInput source="features.disableIcecat" />
                    <InverseBooleanInput source="features.disableReturnForm" />
                    <InverseBooleanInput source="features.disableCo2" />
                    <InverseBooleanInput source="features.disableExternalOrders" />
                    <InverseBooleanInput source="features.disablePurchaseSeparately" />
                    <InverseBooleanInput source="features.disableManualSale" />
                    <InverseBooleanInput source="features.disableGiabsSale" />
                </FormTab>
                <FormTab label={translate('resources.settings.tab.theme')}>
                    <ImageInput source="logoFile" accept="image/*">
                        <ImageField source="src" title="title" />
                    </ImageInput>
                    <HexInputColorPickerInput source="loginPageBackgroundColor" />
                    <ThemePaletteInput source="defaultTheme" />
                </FormTab>
                <FormTab label={translate('resources.settings.tab.zebra')}>
                    <NumberInput source="zebra.leftOffset" validate={required()} />
                    <NumberInput source="zebra.topOffset" validate={required()} />
                </FormTab>
                <FormTab label={translate('resources.settings.tab.statusSettings')}>
                    <NumberInput
                        source="statusSettings.autoCompleteStatusAfterNumberOfDays"
                        validate={required()}
                        style={{ minWidth: '350px' }}
                    />
                </FormTab>
                <FormTab label={translate('resources.settings.tab.giabs')}>
                    <NumberInput source="giabs.soldStatusId" validate={required()} />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

const ImageField: FC<ImageFieldProps> = ({ source, ...props }) => {
    let record = useRecordContext(props);

    // noinspection SuspiciousTypeOfGuard
    if (typeof record === 'string' && source) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        record = { [source]: record };
    }

    return <RaImageField {...props} source={source} record={record} />;
};

const SaveButton = (props: Pick<ToolbarProps, 'handleSubmitWithRedirect'>) => {
    const { pristine } = useFormState({ subscription: { pristine: true } });

    return <RaSaveButton disabled={pristine} {...props} />;
};

const Toolbar: FC<ToolbarProps> = (props) => {
    const isSmall = useIsSmallScreen();

    return (
        <RaToolbar {...props} width={isSmall ? 'xs' : 'md'}>
            <SaveButton handleSubmitWithRedirect={props.handleSubmitWithRedirect} />
        </RaToolbar>
    );
};

export default SettingsEdit;
