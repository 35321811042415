import { ReactNode } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

const FormSpySubscription = { values: true, pristine: true, touched: true };

const useStyles = makeStyles<Theme, { disableMinHeight?: boolean }>((theme) => ({
    form: ({ disableMinHeight }) => ({
        paddingTop: 0,
        display: 'flex',
        alignItems: 'flex-end',
        flexWrap: 'wrap',
        minHeight: disableMinHeight ? 'unset' : theme.spacing(10),
    }),
}));

const FilterForm = <T extends Record<string, any>>({
    onChange,
    initialValues,
    children,
    disableMinHeight,
}: {
    onChange: (filters: T) => void;
    initialValues?: T;
    children: ReactNode;
    disableMinHeight?: boolean;
}) => {
    const classes = useStyles({ disableMinHeight });

    return (
        <Form
            initialValues={initialValues}
            onSubmit={(values) => onChange(values as T)}
            render={() => (
                <>
                    <FormSpy
                        subscription={FormSpySubscription}
                        onChange={({ touched, pristine, values }) => {
                            if (
                                pristine &&
                                (!touched || Object.values(touched).every((fieldTouched) => !fieldTouched))
                            ) {
                                return;
                            }

                            onChange(values as T);
                        }}
                    />
                    <form
                        className={classes.form}
                        onSubmit={(event) => {
                            event.preventDefault();
                            return false;
                        }}
                    >
                        {children}
                    </form>
                </>
            )}
        />
    );
};

export default FilterForm;
