/* eslint-disable react/jsx-key */
import { useMemo } from 'react';
import { NullableBooleanInput, NumberInput, SearchInput, useTranslate } from 'react-admin';

import QrCodeScannerFilter from '@components/filter/QrCodeScannerFilter';
import BarcodeScannerFilter from '@components/filter/BarcodeScannerFilter';
import DateRangeFilter from '@components/filter/DateRangeFilter';
import { ScrollableSelectInputGuesser } from '@components/form/InputGuesser';
import ReferenceRecursiveSelectInput from '@components/input/ReferenceRecursiveSelectInput';
import BooleanFilterInput from '@components/input/BooleanFilterInput';
import HierarchicalAutocompleteSelectInput from '@components/form/HierarchicalAutocompleteSelectInput';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import { useResourcePermissions } from '@js/context/UserPermissionsContext';

/*
 * !!!
 * By changing filters,
 * remember to update filters in assets/js/components/resource/user_groups/input/PermissionsInput/FilterSettings.tsx => ReclamationFilterSettings.tsx !!!
 */

const useFilters = (props: { resource?: string }) => {
    const translateField = useTranslateResourceField(props.resource);
    const translate = useTranslate();
    const { isFilterAlwaysOn } = useResourcePermissions(props);

    return useMemo(
        () => [
            <SearchInput source="q" alwaysOn autoComplete="off" />,
            <QrCodeScannerFilter source="qrcode" alwaysOn />,
            <BarcodeScannerFilter source="barcode" alwaysOn />,
            <ReferenceRecursiveSelectInput source="status" alwaysOn multiple />,
            <BooleanFilterInput source="exists[status]" alwaysOn inverse />,
            <BooleanFilterInput source="inStock" alwaysOn />,
            <BooleanFilterInput
                source="exists[savings.error]"
                label={translate('app.label.savings_calculation_error')}
                alwaysOn={isFilterAlwaysOn('exists[savings.error]')}
            />,
            <DateRangeFilter source="createdAt" alwaysOn={isFilterAlwaysOn('createdAt')} />,
            <DateRangeFilter source="updatedAt" alwaysOn={isFilterAlwaysOn('updatedAt')} />,
            <DateRangeFilter source="sales.soldAt" minWith={175} alwaysOn={isFilterAlwaysOn('sales.soldAt')} />,
            <ScrollableSelectInputGuesser
                reference="models"
                source="model"
                filter={{
                    'exists[reclamations]': true,
                }}
                alwaysOn={isFilterAlwaysOn('model')}
            />,
            <ScrollableSelectInputGuesser
                label={translateField('assignedUser', 'errands')}
                reference="users"
                source="errand.assignedUser"
                filter={{
                    'exists[errands]': true,
                }}
                sort={{
                    field: 'firstName',
                    order: 'ASC',
                }}
                alwaysOn={isFilterAlwaysOn('errand.assignedUser')}
            />,
            <ScrollableSelectInputGuesser
                reference="types"
                source="type"
                filter={{
                    'exists[reclamations]': true,
                }}
                alwaysOn={isFilterAlwaysOn('type')}
            />,
            <ScrollableSelectInputGuesser
                reference="resellers"
                source="resellers"
                alwaysOn={isFilterAlwaysOn('resellers')}
            />,
            <HierarchicalAutocompleteSelectInput
                label={translateField('store', 'stores')}
                reference="stores"
                source="errand.store"
                multiple
                alwaysOn={isFilterAlwaysOn('errand.store')}
            />,
            <NullableBooleanInput source="credited" alwaysOn={isFilterAlwaysOn('credited')} />,
            <ReferenceRecursiveSelectInput source="category" filter={{ entrypoint: true }} multiple />,
            <DateRangeFilter source="statusUpdatedAt" alwaysOn={isFilterAlwaysOn('statusUpdatedAt')} minWith={175} />,
            <NullableBooleanInput
                label={translateField('paid', 'sello_sales')}
                source="selloSales.selloOrderRow.selloOrder.paid"
                alwaysOn={isFilterAlwaysOn('selloSales.selloOrderRow.selloOrder.paid')}
            />,
            <NullableBooleanInput
                label={translateField('deliveredAt', 'sello_sales')}
                source="selloSales.selloOrderRow.selloOrder.deliveredAt"
                alwaysOn={isFilterAlwaysOn('selloSales.selloOrderRow.selloOrder.deliveredAt')}
            />,
            <NullableBooleanInput
                label={translateField('canceledAt', 'sello_sales')}
                source="selloSales.selloOrderRow.selloOrder.canceledAt"
                alwaysOn={isFilterAlwaysOn('selloSales.selloOrderRow.selloOrder.canceledAt')}
            />,
            <NumberInput source="id" />,
        ],
        [isFilterAlwaysOn, translate, translateField],
    );
};

export default useFilters;
