import { useState } from 'react';
import { useLocale, useTranslate } from 'react-admin';
import { Box, Button, ButtonGroup } from '@material-ui/core';

import List from '@components/Dashboard/List';

import { Saving, SavingType } from '@js/interfaces/Saving';

type Props = {
    savings: Record<SavingType, Record<Saving, number>>;
};

const ListRecordSavings = ({ savings }: Props) => {
    const locale = useLocale();
    const translate = useTranslate();
    const [showType, setShowType] = useState(SavingType.IncludePurchasePrice);

    const items = (Object.entries(savings[showType]) as Array<[Saving, number]>).map(([saving, value]) => ({
        label: translate(`app.co2.savings.${saving}`),
        value: value.toLocaleString(locale),
    }));

    return (
        <>
            <Box my={1}>
                <ButtonGroup variant="contained" color="primary" size="small">
                    {Object.values(SavingType).map((type) => (
                        <Button
                            key={type}
                            variant={type === showType ? 'outlined' : 'contained'}
                            onClick={() => setShowType(type)}
                        >
                            {translate(`app.co2.${type}`)}
                        </Button>
                    ))}
                </ButtonGroup>
            </Box>
            <List items={items} />
        </>
    );
};

export default ListRecordSavings;
