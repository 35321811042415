import { useEffect, useRef, useState } from 'react';

import { useEntrypoint } from '@js/context/AppConfigContext';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import { get } from '@js/request/apiRequest';
import { useLocale } from 'react-admin';

const STORAGE_KEY = 'app/resource_export/selected_fields';

type StorageState = Record<string, string[]>;

export type ExportField = { name: string; label: string };
const getSelectedFromLocalStorage = (): StorageState => {
    const serializedState = window.localStorage.getItem(STORAGE_KEY);
    const defaultState = {};

    if (serializedState) {
        try {
            return JSON.parse(serializedState) || defaultState;
        } catch (error) {}
    }

    return defaultState;
};

const saveSelectedInStorage = (state: StorageState) => {
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
};

const useResourceExportFields = (resource: string) => {
    const selectedFromLocalStorage = getSelectedFromLocalStorage();
    const [selectedFields, setSelectedFields] = useState<string[]>(selectedFromLocalStorage[resource]);

    const [loading, setLoading] = useState(true);
    const [fields, setFields] = useState<ExportField[]>();

    const entrypoint = useEntrypoint();
    const notifyHttpError = useNotifyHttpError();
    const locale = useLocale();
    const lastLocale = useRef<string>(locale);

    useEffect(() => {
        if (fields && lastLocale.current === locale) {
            return;
        }

        setLoading(true);
        lastLocale.current = locale;

        get<{ fields: ExportField[] }>(`${entrypoint}/${resource}/export_fields`, { locale })
            .then((response) => response.fields)
            .then((exportFields) => {
                setFields(exportFields);
                // Select all fields by default
                if (!selectedFields) {
                    setSelectedFields(exportFields.map((field) => field.name));
                }
            })
            .catch((error) => {
                notifyHttpError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [entrypoint, fields, notifyHttpError, resource, selectedFields, locale]);

    const fieldNames = fields?.map((field) => field.name) || [];
    const isAllFieldsSelected =
        Array.isArray(selectedFields) && fieldNames.every((fieldName) => selectedFields.includes(fieldName));

    const onSelectFieldChange = (fieldName: string) => {
        let newSelectedFields: string[];
        if (selectedFields.includes(fieldName)) {
            newSelectedFields = selectedFields.filter((field) => field !== fieldName);
        } else {
            newSelectedFields = [...selectedFields, fieldName];
        }

        setSelectedFields(newSelectedFields);
        saveSelectedInStorage({ ...selectedFromLocalStorage, [resource]: newSelectedFields });
    };

    const toggleSelectAll = () => {
        const newSelectedFields = isAllFieldsSelected ? [] : fieldNames;

        setSelectedFields(newSelectedFields);
        saveSelectedInStorage({ ...selectedFromLocalStorage, [resource]: newSelectedFields });
    };

    return {
        loading,
        fields: fields || [],
        selectedFields: selectedFields || [],
        onSelectFieldChange,
        isAllFieldsSelected,
        toggleSelectAll,
    };
};

export default useResourceExportFields;
