import { Record as RaRecord, SelectInput, Validator } from 'react-admin';
import useGetIdentity from '@js/hooks/useGetIdentity';
import { useCurrencyChoices } from '@js/context/AppConfigContext';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';

type Props = {
    source: string;
    label?: string;
    validate?: Validator;
    fullWidth?: boolean;
    resource?: string;
    record?: RaRecord;
};

const CurrencySelectInput = (props: Props) => {
    const { identity } = useGetIdentity();
    const currencyChoices = useCurrencyChoices();
    const getFieldLabel = useTranslateResourceField();

    if (!identity) return null;

    return (
        <SelectInput
            label={getFieldLabel('currency', 'errands')}
            choices={currencyChoices}
            defaultValue={identity.currency}
            {...props}
        />
    );
};

export default CurrencySelectInput;
