import { FC } from 'react';
import {
    Datagrid,
    DateField,
    FunctionField,
    List,
    ListProps,
    NullableBooleanInput,
    NumberInput,
    Record,
    TextField,
    useGetResourceLabel,
} from 'react-admin';
import { Typography } from '@material-ui/core';

import RowActions from '@components/list/RowActions';
import CreateErrandFromInitialValuesButton from './CreateErrandFromInitialValuesButton';
import ErrandDraftExpandPanel from './ErrandDraftExpandPanel';

import { ErrandDraft } from '@js/interfaces/erranddraft';

const defaultSort = { field: 'createdAt', order: 'DESC' };

const rowStyle = (record: Record) => ({
    ...((record?.errands?.length ?? 0) <= 0 && { backgroundColor: '#efe' }),
});

const filters = [
    <NullableBooleanInput
        key="exists[errands]"
        source="exists[errands]"
        nullLabel="resources.errand_drafts.filter.errands.all"
        falseLabel="resources.errand_drafts.filter.errands.new"
        trueLabel="resources.errand_drafts.filter.errands.already_used"
        alwaysOn
        style={{ minWidth: '200px' }}
    />,
    <NumberInput key="id" source="id" />,
];

const ErrandDraftList: FC<ListProps> = (props) => {
    const translateResource = useGetResourceLabel();

    return (
        <List {...props} exporter={false} sort={defaultSort} filters={filters}>
            <Datagrid rowStyle={rowStyle} expand={<ErrandDraftExpandPanel />}>
                <DateField source="createdAt" showTime />
                <TextField source="name" sortable={false} />
                <FunctionField<ErrandDraft>
                    label={translateResource('errands')}
                    render={(record) => (
                        <Typography component="span" variant="body2">
                            {record?.errands?.length || 0}
                        </Typography>
                    )}
                />
                <RowActions editButton={CreateErrandFromInitialValuesButton} hasEdit hasDelete />
            </Datagrid>
        </List>
    );
};

export default ErrandDraftList;
