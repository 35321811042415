import { ApiRecord, Iri } from '@js/interfaces/ApiRecord';

export enum ACTION {
    VIEW = 'view',
    CREATE = 'create',
    UPDATE = 'update',
    REMOVE = 'remove',
    SELLO_SYNC = 'sello_sync',
    WEB_HOOK = 'web_hook',
    PURCHASE_SEPARATELY = 'purchase_separately',
    ACTION_PURCHASE_SEPARATELY_CANCEL = 'purchase_separately_cancel',
    ACTION_GIAB_S_WEB_HOOK = 'giab_s_web_hook',
}

export enum LogLevel {
    EMERGENCY = 'emergency',
    ALERT = 'alert',
    CRITICAL = 'critical',
    ERROR = 'error',
    WARNING = 'warning',
    NOTICE = 'notice',
    INFO = 'info',
    DEBUG = 'debug',
}

export interface LogEntry extends ApiRecord {
    action: ACTION;
    level: LogLevel;
    loggedAt: string;
    objectClass: string;
    objectId: string;
    user?: Iri;
    resource: string;
    resourceIri: string;
    username?: string;
}
