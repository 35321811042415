import { ExternalSale } from '@js/interfaces/externalSale';
import { Datagrid, DateField, List, ListProps, Pagination, TextField, useRecordContext } from 'react-admin';

import ReferenceField from '@components/field/ReferenceField';
import DateRangeFilter from '@components/filter/DateRangeFilter';
import RecordPriceField from '@components/field/RecordPriceField';
import ReferenceFieldGuesser from '@components/field/ReferenceFieldGuesser';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';

const ExternalSaleList = (props: ListProps) => {
    const getFieldLabel = useTranslateResourceField();

    return (
        <List
            {...props}
            filters={filters}
            exporter={false}
            sort={{ field: 'createdAt', order: 'DESC' }}
            pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 200]} />}
        >
            <Datagrid>
                <DateField source="createdAt" showTime />
                <ReferenceIdentifierField
                    label={getFieldLabel('composedIdentifier', 'reclamations')}
                    sortable={false}
                />
                <ReferenceFieldGuesser source="saleChannel" sortable={false} />
                <TextField source="quantity" sortable={false} />
                <RecordPriceField source="salesPrice" currencyField="currency" />
            </Datagrid>
        </List>
    );
};

const ReferenceIdentifierField = (props: { label: string; record?: ExternalSale; sortable?: boolean }) => {
    const record = useRecordContext(props);
    const [id, reference] = record?.product
        ? [record.product, 'products']
        : record?.reclamation
        ? [record.reclamation, 'reclamations']
        : [null, null];

    if (!id || !reference) return null;

    return (
        <ReferenceField source="id" reference={reference} record={{ id }} link="show">
            <TextField source="composedIdentifier" />
        </ReferenceField>
    );
};

// eslint-disable-next-line react/jsx-key
const filters = [<DateRangeFilter source="createdAt" alwaysOn />];

export default ExternalSaleList;
