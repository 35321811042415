import { BooleanField, ListProps, SearchInput, SelectField, SelectInput, useRecordContext } from 'react-admin';
import { Box } from '@material-ui/core';

import ListGuesser, { DatagridGuesser } from '@components/list/ListGuesser';
import EditIconButton from '@components/button/EditIconButton';

import MigrateDeleteIconButton from './MigrateDeleteIconButton';

import useSaleChannelTypeChoices from './useSaleChannelTypeChoices';
import { SaleChannel } from '@js/interfaces/SaleChannel';

const SaleChannelList = (props: ListProps) => {
    const saleChannelTypeChoices = useSaleChannelTypeChoices();

    const filters = [
        <SearchInput key="q" source="q" alwaysOn />,
        <SelectInput key="type" source="type" alwaysOn translateChoice={false} choices={saleChannelTypeChoices} />,
    ];

    return (
        <ListGuesser {...props} filters={filters}>
            <DatagridGuesser rowActions={<RowActions />}>
                <BooleanField source="selloIntegrationId" looseValue />
                <SelectField source="type" choices={saleChannelTypeChoices} />
            </DatagridGuesser>
        </ListGuesser>
    );
};

const RowActions = (props: { resource?: string; record?: SaleChannel; basePath?: string }) => {
    const record = useRecordContext(props);

    if (!record) return null;

    const disableEditReason =
        record.selloIntegrationId || record.selloIntegrationId === 0
            ? 'resources.sale_channels.edit_disable_reason.is_sello'
            : null;

    return (
        <Box display="flex" justifyContent="end">
            <EditIconButton disabledReason={disableEditReason} />
            <MigrateDeleteIconButton />
        </Box>
    );
};

export default SaleChannelList;
