import { useEffect, useState } from 'react';
import { AutocompleteInput, CardContentInner, useTranslate } from 'react-admin';
import { Form } from 'react-final-form';
import { Box, Grid, LinearProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import Toolbar from '@components/routes/Toolbar';

import { post } from '@js/request/apiRequest';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import { State } from './ExternalOrdersImport';

type Props = {
    state: State;
    onSubmit: (values: State['mapping']) => void;
    onGoBack: () => void;
};

type Field = 'identifier' | 'quantity' | 'price' | 'soldAt';

const defaultMapping: Record<string, Field> = {
    // A: 'channel',
    A: 'identifier',
    B: 'quantity',
    C: 'price',
    D: 'soldAt',
    // E: 'channel, channel is now preselected'
};

const requiredFields: Field[] = [/*'channel',*/ 'identifier', 'quantity', 'price'];

const fieldsToLabel: Record<Field, string> = {
    // channel: 'resources.external_sales.fields.channel',
    identifier: 'resources.reclamations.fields.composedIdentifier',
    quantity: 'resources.external_sales.fields.quantity',
    price: 'resources.external_sales.fields.salesPrice',
    soldAt: 'resources.external_sales.fields.soldAt',
};

const choices = Object.entries(fieldsToLabel).map(([field, name]) => ({
    id: field,
    name: name,
}));

type FormValues = { mapping?: Record<string, string> };

const MappingStep = ({ onSubmit, onGoBack, state }: Props) => {
    const [firstLine, setFirstLine] = useState<Record<string, string>>();
    const translate = useTranslate();
    const notifyError = useNotifyHttpError();
    const importFile = state.upload?.file.rawFile;

    useEffect(() => {
        if (!importFile || firstLine) return;

        const form = new FormData();
        form.append('file', importFile);

        post<{ columns: Record<string, string> }>('/api/excel/first_line_reader', {
            body: form,
        })
            .then((response) => {
                setFirstLine(response.columns);
            })
            .catch((error) => {
                notifyError(error);
            });
    }, [firstLine, importFile, notifyError]);

    return (
        <Form<FormValues>
            onSubmit={(values: FormValues) => {
                onSubmit(values.mapping);
            }}
            validate={(values) => {
                const mappedFields = Object.values(values.mapping ?? {});
                const missingFields = requiredFields.filter((field) => !mappedFields.includes(field));

                if (missingFields.length) {
                    return {
                        missingFields: missingFields.map((field) =>
                            field in fieldsToLabel
                                ? translate(fieldsToLabel[field as keyof typeof fieldsToLabel])
                                : field,
                        ),
                    };
                }
            }}
        >
            {({ handleSubmit, valid, submitting, errors }) => (
                <>
                    <CardContentInner>
                        {firstLine ? (
                            <>
                                <Grid container spacing={1}>
                                    {Object.entries(firstLine).map(([column, label]) => (
                                        <Grid key={column} item>
                                            <AutocompleteInput
                                                label={label}
                                                source={`mapping[${column}]`}
                                                choices={choices}
                                                initialValue={
                                                    column in defaultMapping
                                                        ? defaultMapping[column as keyof typeof defaultMapping]
                                                        : null
                                                }
                                                validate={(value: string | undefined, allValues?: FormValues) => {
                                                    if (!value) return;
                                                    const repeated =
                                                        Object.values(allValues?.mapping ?? {})?.filter(
                                                            (field) => field === value,
                                                        ).length ?? 0;

                                                    if (repeated > 1) return 'app.validation.unique';
                                                }}
                                                emptyValue={null}
                                                allowEmpty
                                                fullWidth
                                            />
                                        </Grid>
                                    ))}
                                    {errors?.missingFields && (
                                        <Grid item xs={12}>
                                            <Alert severity="error">
                                                <span>{translate('app.error.missing_required_fields')}</span>
                                                <b>: {errors.missingFields.join(', ')}</b>
                                            </Alert>
                                        </Grid>
                                    )}
                                </Grid>
                            </>
                        ) : (
                            <Box my={2}>
                                <LinearProgress />
                            </Box>
                        )}
                    </CardContentInner>
                    <Toolbar
                        cancel={{ onClick: onGoBack }}
                        submit={{
                            onSubmit: handleSubmit,
                            disabled: !valid,
                            saving: submitting,
                            label: 'ra.navigation.next',
                            icon: NavigateNextIcon,
                        }}
                    />
                </>
            )}
        </Form>
    );
};

export default MappingStep;
