import { ApiRecord } from '@js/interfaces/ApiRecord';

export enum SaleChannelType {
    Sello = 'sello',
    Imported = 'imported',
    PurchaseSeparately = 'purchase_separately',
}

export interface SaleChannel extends ApiRecord {
    name: string;
    readonly type: SaleChannelType;
    readonly selloIntegrationId: number | null;
    readonly countSales: number | null;
}
