import { useState } from 'react';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import {
    Datagrid,
    DatagridProps,
    ListBase,
    NumberField,
    Pagination,
    ResourceContextProvider,
    TextField,
    useTranslate,
} from 'react-admin';
import { Box, Card, Typography } from '@material-ui/core';

import PriceField from '@components/field/PriceField';
import HierarchicalAutocompleteSelectInput from '@components/form/HierarchicalAutocompleteSelectInput';

import DateRangeInput from '../input/DateRangeInput';
import FilterForm from '../FilterForm';

export type Filter = {
    'reclamation.errand.store'?: string;
    after?: string;
    before?: string;
};

const SaleChannelsStatistics = () => {
    const [filter, setFilter] = useState<Filter>();
    const translate = useTranslate();
    const getResourceLabel = useTranslateResourceField();
    const resource = 'sale_channel_statistics_items';

    return (
        <>
            <FilterForm initialValues={filter} onChange={setFilter}>
                <Box display="flex" alignItems="center" gridGap="20px">
                    <HierarchicalAutocompleteSelectInput
                        label={getResourceLabel('stores')}
                        reference="stores"
                        source="reclamation.errand.store"
                        variant="standard"
                    />
                    <DateRangeInput />
                </Box>
            </FilterForm>
            <Box mt={2}>
                <Typography variant="h6" gutterBottom>
                    {translate('app.label.sales')}
                </Typography>
                <ResourceContextProvider value={resource}>
                    <ListBase
                        resource={resource}
                        basePath={`/${resource}`}
                        filter={filter}
                        sort={{ field: 'totalSalesSEK', order: 'DESC' }}
                    >
                        <Card>
                            <SaleChannelsDatagrid />
                            <Pagination />
                        </Card>
                    </ListBase>
                </ResourceContextProvider>
            </Box>
        </>
    );
};

export const SaleChannelsDatagrid = (props: DatagridProps) => {
    const getFieldLabel = useTranslateResourceField();
    const translate = useTranslate();

    return (
        <Datagrid {...props}>
            <TextField source="channel" label={getFieldLabel('channel', 'external_sales')} />
            <PriceField
                source="totalSalesSEK"
                label={`${translate('app.label.total_sales')} SEK`}
                formatCents={false}
            />
            <PriceField
                source="totalSalesNOK"
                label={`${translate('app.label.total_sales')} NOK`}
                formatCents={false}
            />
            <PriceField
                source="totalSalesDKK"
                label={`${translate('app.label.total_sales')} DKK`}
                formatCents={false}
            />
            <NumberField source="totalCount" label={translate('app.label.total')} />
        </Datagrid>
    );
};

export default SaleChannelsStatistics;
