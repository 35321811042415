import { FC, MouseEvent, PropsWithChildren, useState } from 'react';
import { Box, BoxProps, Card, IconButton, Tab as MuiTab, Tabs, Typography } from '@material-ui/core';
import {
    Datagrid,
    Identifier,
    linkToRecord,
    ListBase,
    Pagination,
    Record as RaRecord,
    ReferenceManyField,
    ResourceContextProvider,
    TextField,
    useGetResourceLabel,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import { useHistory } from 'react-router-dom';
import ViewIcon from '@material-ui/icons/RemoveRedEye';
import EditIcon from '@material-ui/icons/Create';

import ListRecordSavings from './ListRecordSavings';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import { MainSaving, Saving, SavingType } from '@js/interfaces/Saving';
import { Iri } from '@js/interfaces/ApiRecord';
import { FilterValues } from './Filters';

type Props = {
    filter?: FilterValues;
};

type Savings = Record<SavingType, Record<Saving, number>>;

type ErrandSavings = {
    id: Identifier;
    name: string;
    errand: Iri;
    composedIdentifier: string;
    errorCount: number;
    savings: Savings;
};

type ReclamationSavings = {
    id: Identifier;
    name: string;
    reclamation: Iri;
    composedIdentifier: string;
    savings: Savings;
    error: string | null;
};

const ErrandSavingsList = ({ filter }: Props) => {
    const getResourceLabel = useGetResourceLabel();
    const translate = useTranslate();
    const getFieldLabel = useTranslateResourceField('errands');
    const resource = 'errand_savings';

    return (
        <ResourceContextProvider value={resource}>
            <ListBase
                resource={resource}
                basePath={`/${resource}`}
                filter={{
                    store: filter?.store,
                    'reclamations.createdAt[after]': filter?.after,
                    'reclamations.createdAt[before]': filter?.before,
                }}
                sort={{ field: `savings.${SavingType.IncludePurchasePrice}.${MainSaving}`, order: 'DESC' }}
            >
                <Box display="flex" alignItems="end" mb={1}>
                    <Typography variant="h6" style={{ marginBottom: '4px' }}>
                        {getResourceLabel('errands')}
                    </Typography>
                </Box>
                <Card>
                    <Datagrid expand={<ExpandErrandSavings />} rowClick="expand">
                        <TextField
                            source="composedIdentifier"
                            label={getFieldLabel('composedIdentifier')}
                            sortable={false}
                        />
                        <TextField
                            source={`savings.${SavingType.IncludePurchasePrice}.${MainSaving}`}
                            label={`${translate(`app.co2.savings.${MainSaving}`)} (${translate(
                                `app.co2.${SavingType.IncludePurchasePrice}`,
                            )})`}
                        />
                        <TextField
                            source={`savings.${SavingType.ExcludePurchasePrice}.${MainSaving}`}
                            label={`${translate(`app.co2.savings.${MainSaving}`)} (${translate(
                                `app.co2.${SavingType.ExcludePurchasePrice}`,
                            )})`}
                        />
                        <TextField source="errorCount" label={translate('app.label.error_count')} />
                        <RowActions targetResource="errands" />
                    </Datagrid>
                    <Pagination />
                </Card>
            </ListBase>
        </ResourceContextProvider>
    );
};

type Tab = 'savings' | 'reclamation_savings';

const ExpandErrandSavings = (props: { record?: ErrandSavings }) => {
    const record = useRecordContext(props);
    const [tab, setTab] = useState<Tab>('savings');
    const translate = useTranslate();
    const getResourceLabel = useGetResourceLabel();
    const getResourceFieldLabel = useTranslateResourceField('reclamations');

    if (!record) return null;

    return (
        <>
            <Tabs value={tab} onChange={(_, activeTab) => setTab(activeTab)}>
                <MuiTab value="savings" label={translate('app.action.co2')} />
                <MuiTab value="reclamation_savings" label={getResourceLabel('reclamations')} />
            </Tabs>
            <TabPanel activeTab={tab} tab="savings" pl={1} pb={1}>
                <ListRecordSavings savings={record.savings} />
            </TabPanel>
            <TabPanel activeTab={tab} tab="reclamation_savings" pl={1} pb={1}>
                <ReferenceManyField
                    reference="reclamation_savings"
                    target="errand"
                    record={{ id: record.errand }}
                    sort={{ field: `savings.${SavingType.IncludePurchasePrice}.${MainSaving}`, order: 'DESC' }}
                    perPage={10}
                    pagination={<Pagination />}
                >
                    <Datagrid expand={<ExpandReclamationSavings />} rowClick="expand">
                        <TextField source="label" label={getResourceFieldLabel('label')} sortable={false} />
                        <TextField
                            source="composedIdentifier"
                            label={getResourceFieldLabel('composedIdentifier')}
                            sortable={false}
                        />
                        <TextField
                            source={`savings.${SavingType.IncludePurchasePrice}.${MainSaving}`}
                            label={`${translate(`app.co2.savings.${MainSaving}`)} (${translate(
                                `app.co2.${SavingType.IncludePurchasePrice}`,
                            )})`}
                        />
                        <TextField
                            source={`savings.${SavingType.ExcludePurchasePrice}.${MainSaving}`}
                            label={`${translate(`app.co2.savings.${MainSaving}`)} (${translate(
                                `app.co2.${SavingType.ExcludePurchasePrice}`,
                            )})`}
                        />
                        <TextField source="errorCount" label={translate('app.label.error_count')} />
                        <RowActions targetResource="reclamations" linkType="edit" />
                    </Datagrid>
                </ReferenceManyField>
            </TabPanel>
        </>
    );
};

const ExpandReclamationSavings = (props: { record?: ReclamationSavings }) => {
    const record = useRecordContext(props);

    if (!record?.savings) return null;

    return <ListRecordSavings savings={record.savings} />;
};

const TabPanel: FC<PropsWithChildren<{ activeTab: Tab; tab: Tab } & BoxProps>> = ({
    children,
    activeTab,
    tab,
    ...rest
}) => {
    const isActive = activeTab === tab;

    return (
        <Box hidden={!isActive} {...rest}>
            {isActive && children}
        </Box>
    );
};

const RowActions = ({
    targetResource,
    linkType = 'show',
    ...props
}: {
    targetResource: 'errands' | 'reclamations';
    record?: RaRecord;
    linkType?: 'show' | 'edit';
}) => {
    const record = useRecordContext(props);
    const history = useHistory();
    const translate = useTranslate();

    if (!record) return null;

    const originPropertyMap = {
        errands: 'errand',
        reclamations: 'reclamation',
    };

    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();

        history.push({
            pathname: record
                ? linkToRecord(`/${targetResource}`, record[originPropertyMap[targetResource]] as Identifier, linkType)
                : '',
            state: { _scrollToTop: true },
        });
    };

    return (
        <Box display="flex" justifyContent="end">
            <IconButton title={translate(`ra.action.${linkType}`)} onClick={handleClick} size="small">
                {linkType === 'show' ? <ViewIcon fontSize="inherit" /> : <EditIcon fontSize="inherit" />}
            </IconButton>
        </Box>
    );
};

export default ErrandSavingsList;
