import StorefrontIcon from '@material-ui/icons/Storefront';

import SaleChannelList from './SaleChannelList';

import { RegisterResource } from '@components/ResourceGuesser';
import { SaleChannel } from '@js/interfaces/SaleChannel';

export default {
    icon: StorefrontIcon,
    list: SaleChannelList,
    show: null,
    options: {
        alwaysHideFields: ['selloIntegrationId'],
    },
} satisfies RegisterResource<SaleChannel>;
