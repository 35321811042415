import { ApiRecord, Iri } from '@js/interfaces/ApiRecord';
import { ResellerEmbedded } from '@js/interfaces/reseller';
import { ProcessingTimeEmbedded } from '@js/interfaces/processingtime';
import { StatusNotification } from '@js/interfaces/statusNotification';

// Mirror of App\Errand\ReclamationComponent
export enum Component {
    BatteryOrElectronic = 'battery_or_electronic',
    TextilesOrPadding = 'textiles_or_padding',
}
export const ComponentChoices = Object.values(Component).map((component) => ({
    id: component,
    name: `resources.reclamations.component.${component}`,
}));

export interface Reclamation extends ApiRecord {
    printLabel?: boolean;
    errand: Iri;
    withinComplaintPeriod?: boolean;
    quantity: number;
    goodsType?: Iri;
    manufacturer?: Iri;
    model?: Iri;
    supplier?: Iri;
    sku?: string;
    serialNumber?: string;
    rmaSerialNumber?: string;
    purchasePrice?: number;
    insurancePrice?: number;
    margin?: number;
    resellers?: Iri[];
    overrideResellers?: ResellerEmbedded[];
    ean?: string;
    supplierSerialNumber?: string;
    url?: string;
    workshop?: string;
    images?: Iri[];
    defect?: Iri;
    functionality?: Iri;
    state?: Iri;
    type?: Iri;
    grade?: Iri;
    soldGrade?: Iri;
    depreciation?: Iri;
    outgoingPrice?: number;
    title?: string;
    status?: Iri;
    cost?: number;
    processingTimes?: ProcessingTimeEmbedded[];
    costRedeemedItem?: number;
    statusNotifications?: StatusNotification[];
    caseNumber?: string;
    recyclingFee?: number;
    sparePart?: string;
    costSparePart?: number;
    links?: any;
    offerPrice?: string;
    classificationComment?: string;
    classificationOutComment?: string;
    readonly composedIdentifier?: string;
    readonly label?: string;
    weight?: number;
    containsComponents: Component[];
    split: boolean;
    selloSyncedAt?: string;
    selloSynced: boolean;
    splitQuantityLeft: number;
    category?: Iri;
    externalIri?: string;
    externalApp?: string;
    parent?: Iri;
    child?: Iri;
    selloProductId?: number;
    material?: Iri;
    shortDescription?: string;
    longDescription?: string;
    readonly inStockQuantity?: number;
    readonly soldQuantity?: number;
    readonly outOfStock?: boolean;
    readonly selloExitingProductSync: boolean;
    readonly statusUpdatedAt?: string;
    readonly copiedFieldsFromSavingsCalculation: string[];
    readonly savingsCalculationError?: string;
    readonly selloAuctionNumber: number | null;
    readonly selloAuctionQueuedNumber: number | null;
}

export type SYSTEM_TYPE = 'co2_import' | 'purchase_separately' | 'returned_item';

export type FlowItem = {
    id: Iri;
    store: string | null;
    label: string;
    composedIdentifier: string;
    createdAt: string;
    isCurrent: boolean;
    systemType: SYSTEM_TYPE | null;
};
