import { FC } from 'react';
import { CardContentInner, FormWithRedirect, SaveButton } from 'react-admin';
import { Box } from '@material-ui/core';

import SimpleToolbar from '@components/form/SimpleToolbar';
import CancelButton from '@components/button/CancelButton';
import DeleteIconButton from '@components/button/DeleteIconButton';

import BundleForm from './BundleForm';
import SimpleForm from './SimpleForm';

import { Product, SplitType } from '@js/interfaces/product';

type Props = { basePath?: string; resource?: string; record?: Product };

const ProductForm: FC<Props> = (props) => {
    const { record } = props;
    // If new record or record has type 'bundle', then show bundle form
    const isBundle = !record?.id || record.splitType === 'bundle';

    return (
        <FormWithRedirect
            {...props}
            redirect="list"
            initialValues={isBundle ? ({ type: 'bundle' } satisfies { type: SplitType }) : undefined}
            render={(formProps) => {
                return (
                    <form>
                        <CardContentInner>
                            {isBundle ? <BundleForm {...props} /> : <SimpleForm {...props} />}
                        </CardContentInner>
                        <SimpleToolbar>
                            <Box display="flex" justifyContent="space-between" alignItems="center" flexGrow={1}>
                                <Box display="flex" gridGap={10}>
                                    <SaveButton
                                        handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                                        saving={formProps.saving}
                                        disabled={formProps.pristine}
                                    />
                                    <CancelButton to={props.basePath!} />
                                </Box>
                                <DeleteIconButton record={record} disabledReason={record?.deleteDisableReason} />
                            </Box>
                        </SimpleToolbar>
                    </form>
                );
            }}
        />
    );
};

export default ProductForm;
