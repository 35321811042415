import { FC } from 'react';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DateInput } from 'react-admin';

interface Props {
    source: string;
    resource?: string;
    label?: string;
    alwaysOn?: boolean;
    minWith?: number;
}

const useStyles = makeStyles<Theme, Pick<Props, 'minWith'>>((theme) => ({
    input: (props) => ({
        minWidth: props.minWith,

        '& + .MuiTextField-root': {
            marginLeft: theme.spacing(1),
        },
    }),
}));

const DateRangeFilter: FC<Props> = ({ source, minWith, ...props }) => {
    const classes = useStyles({ minWith });

    return (
        <Box>
            <DateInput className={classes.input} {...props} source={`${source}[after]`} />
            <DateInput
                className={classes.input}
                {...props}
                source={`${source}[before]`}
                format={(date?: string) => (date ? date.trim().split(' ')[0] : date)}
                parse={(date?: string) => (date ? `${date.trim()} 23:59:59` : date)}
            />
        </Box>
    );
};

export default DateRangeFilter;
