import {
    Datagrid,
    Identifier,
    ListBase,
    Pagination,
    ResourceContextProvider,
    TextField,
    useGetResourceLabel,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import { Box, Card, Typography } from '@material-ui/core';

import ListRecordSavings from './ListRecordSavings';
import TranslatableTextField from '@components/field/TranslatableTextField';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import { MainSaving, Saving, SavingType } from '@js/interfaces/Saving';
import { TranslatableField } from '@js/interfaces/type';
import { FilterValues } from './Filters';

type Props = {
    filter?: FilterValues;
};

type CategorySavings = {
    id: Identifier;
    translatableTitle: TranslatableField;
    errorCount: number;
    savings: Record<SavingType, Record<Saving, number>>;
};

const CategorySavingsList = ({ filter }: Props) => {
    const getResourceLabel = useGetResourceLabel();
    const translate = useTranslate();
    const getFieldLabel = useTranslateResourceField('categories');
    const resource = 'category_savings';

    return (
        <>
            <Typography variant="h6">{getResourceLabel('categories')}</Typography>
            <Box mt={2}>
                <ResourceContextProvider value={resource}>
                    <ListBase
                        resource={resource}
                        basePath={`/${resource}`}
                        filter={{
                            'reclamations.errand.store': filter?.store,
                            'reclamations.createdAt[after]': filter?.after,
                            'reclamations.createdAt[before]': filter?.before,
                        }}
                        sort={{ field: `savings.${SavingType.IncludePurchasePrice}.${MainSaving}`, order: 'DESC' }}
                    >
                        <Card>
                            <Datagrid expand={<Expand />} rowClick="expand">
                                <TranslatableTextField
                                    source="translatableTitle"
                                    label={getFieldLabel('title')}
                                    sortable={false}
                                />
                                <TextField
                                    source={`savings.${SavingType.IncludePurchasePrice}.${MainSaving}`}
                                    label={`${translate(`app.co2.savings.${MainSaving}`)} (${translate(
                                        `app.co2.${SavingType.IncludePurchasePrice}`,
                                    )})`}
                                />
                                <TextField
                                    source={`savings.${SavingType.ExcludePurchasePrice}.${MainSaving}`}
                                    label={`${translate(`app.co2.savings.${MainSaving}`)} (${translate(
                                        `app.co2.${SavingType.ExcludePurchasePrice}`,
                                    )})`}
                                />
                                <TextField source="errorCount" label={translate('app.label.error_count')} />
                            </Datagrid>
                            <Pagination />
                        </Card>
                    </ListBase>
                </ResourceContextProvider>
            </Box>
        </>
    );
};

const Expand = (props: { record?: CategorySavings }) => {
    const record = useRecordContext(props);
    if (!record) return null;

    return <ListRecordSavings savings={record.savings} />;
};

export default CategorySavingsList;
