import { ChangeEvent, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { TextInput, useTranslate } from 'react-admin';
import { Checkbox, FormControlLabel, FormGroup, Grid, InputAdornment } from '@material-ui/core';
import { Field } from '@api-platform/api-doc-parser';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import chunk from 'lodash/chunk';
import debounce from 'lodash/debounce';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';

type Props = {
    fields: Field[];
    checked: (field: Field) => boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    resource?: string;
    translateResource?: string;
};

const useStyles = makeStyles((theme) => ({
    labelDisabled: {
        color: theme.palette.text.disabled,
    },
    labelHighlighted: {
        backgroundColor: theme.palette.action.hover,
    },
}));

const ResourceFieldsSelect = ({ fields, onChange, resource, checked, translateResource }: Props) => {
    const translateField = useTranslateResourceField(translateResource || resource);
    const translate = useTranslate();
    const [query, setQuery] = useState('');
    const classes = useStyles();

    const xs = 2;
    const chunkSize = Math.ceil(fields.length / (12 / xs));

    const setQueryDebounced = debounce(setQuery, 300);
    const isSearching = !!query && query.length >= 3;

    return (
        <Grid container wrap="wrap">
            <Grid item xs={12}>
                <Form onSubmit={(e) => e.preventDefault()}>
                    {() => {
                        return (
                            <>
                                <FormSpy
                                    subscription={{ values: true }}
                                    onChange={({ pristine, values }) => {
                                        if (pristine) {
                                            return;
                                        }

                                        setQueryDebounced(values.query?.toLowerCase() ?? '');
                                    }}
                                ></FormSpy>
                                <TextInput
                                    source="query"
                                    hiddenLabel
                                    label=""
                                    resettable
                                    placeholder={translate('ra.action.search')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon color="disabled" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </>
                        );
                    }}
                </Form>
            </Grid>
            {chunk(fields, chunkSize).map((fields) => (
                <Grid key={fields.map((field) => field.name).join('-')} xs={xs} item>
                    <FormGroup>
                        {fields.map((field) => {
                            const label = translateField(field.name);
                            const disabled = isSearching && !label.toLowerCase().includes(query);

                            return (
                                <FormControlLabel
                                    key={field.name}
                                    control={
                                        <Checkbox checked={checked(field)} onChange={onChange} name={field.name} />
                                    }
                                    label={label}
                                    className={
                                        disabled ? classes.labelDisabled : isSearching ? classes.labelHighlighted : ''
                                    }
                                />
                            );
                        })}
                    </FormGroup>
                </Grid>
            ))}
        </Grid>
    );
};

export default ResourceFieldsSelect;
