import { Button, CardContentInner, Title, useGetResourceLabel, useTranslate } from 'react-admin';
import {
    Box,
    Card,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    LinearProgress,
} from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import { chunk } from 'lodash';
import SaveIcon from '@material-ui/icons/Save';

import SimpleToolbar from '@components/form/SimpleToolbar';

import useResourceExportFields from '@js/hooks/useResourceExportFields';

const ConfigureErrandsExport = () => {
    const translate = useTranslate();
    const params = useParams<{ resource: string }>();

    const errandExportFields = useResourceExportFields('errands');
    const reclamationExportFields = useResourceExportFields('reclamations');
    const loading = errandExportFields.loading || reclamationExportFields.loading;
    const loaded = errandExportFields.fields.length > 0 && reclamationExportFields.fields.length > 0;

    return (
        <>
            <Title title={translate('app.action.configure')} />
            <Card>
                <CardContentInner>
                    {!loaded ? (
                        <LinearProgress />
                    ) : (
                        <>
                            {loading && <LinearProgress />}
                            <Box display="flex" flexDirection="column" gridGap={32}>
                                <SelectExportFields resource="errands" resourceExportController={errandExportFields} />
                                <SelectExportFields
                                    resource="reclamations"
                                    resourceExportController={reclamationExportFields}
                                />
                            </Box>
                        </>
                    )}
                </CardContentInner>
                <SimpleToolbar>
                    <Button
                        disabled={loading}
                        component={Link}
                        variant="contained"
                        label="ra.action.back"
                        to={`/${params.resource}`}
                    >
                        <SaveIcon />
                    </Button>
                </SimpleToolbar>
            </Card>
        </>
    );
};

const SelectExportFields = ({
    resource,
    resourceExportController: { fields, selectedFields, onSelectFieldChange, isAllFieldsSelected, toggleSelectAll },
}: {
    resource: string;
    resourceExportController: ReturnType<typeof useResourceExportFields>;
}) => {
    const translate = useTranslate();
    const getResourceLabel = useGetResourceLabel();

    return (
        <FormControl key={resource} component="fieldset">
            <FormLabel component="legend">
                <Box component="span" mr={3}>
                    {getResourceLabel(resource)}
                </Box>
                <FormControlLabel
                    control={<Checkbox checked={isAllFieldsSelected} onChange={toggleSelectAll} />}
                    label={translate('app.label.select_all')}
                />
            </FormLabel>
            <Box display="flex" flexWrap="wrap">
                {chunk(fields, 8).map((fields, index) => (
                    <FormGroup key={index}>
                        {fields.map((field) => (
                            <FormControlLabel
                                key={field.name}
                                control={
                                    <Checkbox
                                        checked={selectedFields.includes(field.name)}
                                        onChange={() => onSelectFieldChange(field.name)}
                                        name={field.name}
                                    />
                                }
                                label={field.label}
                            />
                        ))}
                    </FormGroup>
                ))}
            </Box>
        </FormControl>
    );
};

export default ConfigureErrandsExport;
