import { FC, PropsWithChildren, useState } from 'react';
import { Box, Tab, Tabs } from '@material-ui/core';
import { Identifier, Labeled, Record, useGetResourceLabel, useTranslate } from 'react-admin';

import GridShowLayout from '@components/detail/GridShowLayout';
import ReclamationFieldGuesser from './ReclamationFieldGuesser';
import SelloSalesShowTab from './SelloSalesShowTab';
import ExternalSalesTab from './ExternalSalesTab';
import PurchaseSeparatelySales from './PurchaseSeparatelySales';
import Logs from '../ErrandList/ExpandPanel/Logs';
import ManualSalesTab from '../ErrandList/ExpandPanel/ManualSales';
import GiabsSales from '../ErrandList/ExpandPanel/GiabsSales';

import useShowFields from '@js/hooks/useShowFields';
import { extractFields } from '@components/resource/errand/util';
import { useUserPermissions } from '@js/context/UserPermissionsContext';
import { useIsFeatureDisabled } from '@js/context/AppConfigContext';

interface ExpandShowPanelProps {
    resource?: string;
    hideFields: string[];
    basePath?: string;
    id?: Identifier;
    record?: Record;
}

type TabType =
    | 'general'
    | 'sello_sales'
    | 'external_sales'
    | 'log_entries'
    | 'manual_sales'
    | 'purchased_separately_sales'
    | 'giabs_sales';

interface TabPanelProps {
    activeTab: TabType;
    tab: TabType;
}

const TabPanel: FC<PropsWithChildren<TabPanelProps>> = ({ children, activeTab, tab }) => {
    const isActive = activeTab === tab;

    return <Box hidden={!isActive}>{isActive && children}</Box>;
};

const ExpandShowPanel: FC<PropsWithChildren<ExpandShowPanelProps>> = ({ hideFields, ...props }) => {
    const showFields = useShowFields(props, hideFields);
    const { rest: fields, extracted: excludeFromGridFields } = extractFields(showFields, ['images']);
    const [activeTab, setActiveTab] = useState<TabType>('general');
    const translate = useTranslate();
    const getResourceLabel = useGetResourceLabel();
    const permissions = useUserPermissions();
    const isFeatureDisabled = useIsFeatureDisabled();

    const isSelloOrdersEnabled = permissions.isResourceEnabled('sello_sales');
    const isExternalOrdersEnabled =
        permissions.isResourceEnabled('external_sales') && !isFeatureDisabled('ExternalOrders');
    const isLogsEnabled = permissions.isResourceEnabled('log_entries');
    const isManualSalesEnabled = permissions.isListActionEnabled('errands', 'manual_sale');
    const isPurchaseSeparatelyEnabled =
        permissions.isListActionEnabled('reclamations', 'purchase_separately') &&
        !isFeatureDisabled('PurchaseSeparately');
    const isGiabsSalesEnabled = permissions.isResourceEnabled('giabs_sales') && !isFeatureDisabled('GiabsSale');

    return (
        <>
            <Tabs value={activeTab} onChange={(_, activeTab) => setActiveTab(activeTab)}>
                <Tab value="general" label={translate('resources.reclamations.tabs.general')} />
                {isSelloOrdersEnabled && <Tab value="sello_sales" label={getResourceLabel('sello_sales')} />}
                {isExternalOrdersEnabled && <Tab value="external_sales" label={getResourceLabel('external_sales')} />}
                {isManualSalesEnabled && <Tab value="manual_sales" label={getResourceLabel('manual_sales')} />}
                {isPurchaseSeparatelyEnabled && (
                    <Tab value="purchased_separately_sales" label={translate('app.action.purchase_separately')} />
                )}
                {isGiabsSalesEnabled && <Tab value="giabs_sales" label={getResourceLabel('giabs_sales')} />}
                {isLogsEnabled && <Tab value="log_entries" label={getResourceLabel('log_entries')} />}
            </Tabs>
            <TabPanel activeTab={activeTab} tab="general">
                <GridShowLayout {...props}>
                    {fields.map((field) => (
                        <ReclamationFieldGuesser key={field.name} source={field.name} field={field} />
                    ))}
                </GridShowLayout>
                {excludeFromGridFields.map((field) => (
                    <Labeled key={field.name} source={field.name} resource={props.resource}>
                        <ReclamationFieldGuesser source={field.name} field={field} />
                    </Labeled>
                ))}
            </TabPanel>
            {isSelloOrdersEnabled && (
                <TabPanel activeTab={activeTab} tab="sello_sales">
                    <SelloSalesShowTab record={props.record} resource={props.resource} target="reclamation" />
                </TabPanel>
            )}
            {isExternalOrdersEnabled && (
                <TabPanel activeTab={activeTab} tab="external_sales">
                    <ExternalSalesTab record={props.record} resource={props.resource} target="reclamation" />
                </TabPanel>
            )}
            {isManualSalesEnabled && (
                <TabPanel activeTab={activeTab} tab="manual_sales">
                    <ManualSalesTab record={props.record} resource={props.resource} target="reclamation" />
                </TabPanel>
            )}
            {isPurchaseSeparatelyEnabled && (
                <TabPanel activeTab={activeTab} tab="purchased_separately_sales">
                    <PurchaseSeparatelySales record={props.record} resource={props.resource} />
                </TabPanel>
            )}
            {isGiabsSalesEnabled && (
                <TabPanel activeTab={activeTab} tab="giabs_sales">
                    <GiabsSales record={props.record} resource={props.resource} />
                </TabPanel>
            )}
            {isLogsEnabled && (
                <TabPanel activeTab={activeTab} tab="log_entries">
                    <Logs hideRecordTitle {...props} />
                </TabPanel>
            )}
        </>
    );
};

export default ExpandShowPanel;
