import { ApiRecord, Iri } from '@js/interfaces/ApiRecord';
import { Country, Currency } from '@js/context/AppConfigContext';

export enum ROLES {
    ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
    ROLE_ADMIN = 'ROLE_ADMIN',
    ROLE_SUPPORT = 'ROLE_SUPPORT',
    ROLE_TECHNICIAN = 'ROLE_TECHNICIAN',

    // Default role
    ROLE_USER = 'ROLE_USER',
}

export interface User extends ApiRecord {
    email: string;
    roles?: ROLES[];
    firstName?: string;
    lastName?: string;
    password?: string;
    locale?: string;
    stores?: Iri[];
    userGroups?: Iri[];
    disableEmailNotifyAboutNotification?: boolean;
    defaultProcessingTimeUserGroup?: Iri;
    archivedAt?: string;
    readonly fullName?: string;
    readonly country: Country;
    readonly currency: Currency;
}
