import { ApiRecord, Iri } from '@js/interfaces/ApiRecord';
import { Identifier } from 'react-admin';

export const SplitTypes = ['simple', 'bundle'] as const;

export type SplitType = (typeof SplitTypes)[number];

export interface Product extends ApiRecord {
    title?: string;
    quantity: number;
    manufacturer?: Iri;
    supplierSerialNumber?: string;
    ean?: string;
    model?: Iri;
    grade?: Iri;
    category?: Iri;
    classificationOutComment?: string;
    reclamation?: Iri;
    selloSyncedAt?: string;
    composedIdentifier: string;
    label: string;
    outOfStock: boolean;
    soldQuantity: number;
    splitType: SplitType;
    bundleItems: Iri[];
    deleteDisableReason: string | null;
    syncedWithSello: boolean;
    insurancePrice?: number;
    selloProductId?: number;
    readonly physicalStoreSale: boolean;
    readonly selloExitingProductSync: boolean;
    readonly selloAuctionNumber: number | null;
    readonly selloAuctionQueuedNumber: number | null;
}

export interface SimpleProductsGroup {
    '@id': Iri;
    id: Identifier;
    title?: string;
    composedIdentifier: string;
    reclamation: Iri;
}
