import { MouseEventHandler } from 'react';
import { linkToRecord, Record as RaRecord, useRecordContext, useResourceContext } from 'react-admin';
import ContentCreate from '@material-ui/icons/Create';
import { useHistory } from 'react-router-dom';

import IconButton, { IconButtonProps } from '@components/button/IconButton';

type Props = {
    label?: string;
    resource?: string;
    basePath?: string;
    record?: RaRecord;
} & Pick<IconButtonProps, 'disabledReason'>;

const EditIconButton = ({ disabledReason, label = 'ra.action.edit', ...props }: Props) => {
    const resource = useResourceContext(props);
    const record = useRecordContext(props);
    const history = useHistory();

    const handleClick: MouseEventHandler = (e) => {
        e.stopPropagation();

        history.push({
            pathname: linkToRecord(props.basePath || `/${resource}`, record?.id, 'edit'),
            state: { _scrollToTop: true },
        });
    };

    return (
        <IconButton label={label} onClick={handleClick} disabledReason={disabledReason}>
            <ContentCreate />
        </IconButton>
    );
};

export default EditIconButton;
