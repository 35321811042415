import { useState } from 'react';
import {
    Button,
    Dialog as MuiDialog,
    DialogActions,
    DialogContent as MuiDialogContent,
    DialogTitle,
} from '@material-ui/core';
import {
    LinearProgress,
    RecordContextProvider,
    required,
    useCreate,
    useGetList,
    useGetMany,
    useGetResourceLabel,
    useNotify,
    useRecordContext,
    useRefresh,
    useResourceContext,
    useTranslate,
} from 'react-admin';
import { Form } from 'react-final-form';
import { Alert } from '@material-ui/lab';
import StoreIcon from '@material-ui/icons/Store';

import IconButton from '@components/button/IconButton';
import LoadingButton from '@components/button/LoadingButton';
import PriceInput from '@components/input/PriceInput';
import HierarchicalAutocompleteSelectInput from '@components/form/HierarchicalAutocompleteSelectInput';
import CurrencySelectInput from '@components/input/CurrencySelectInput';

import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import { cloneErrand, cloneReclamation } from '@js/utility/cloneUtil';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import { useIsFeatureDisabled } from '@js/context/AppConfigContext';

import { Status } from '@js/interfaces/status';
import { Iri } from '@js/interfaces/ApiRecord';
import { Reclamation } from '@js/interfaces/reclamation';
import { Errand } from '@js/interfaces/errand';

type Props = {
    record?: Reclamation;
    resource?: string;
};

const PurchaseSeparatelyButtonView = (props: Props) => {
    const [open, setOpen] = useState(false);
    const record = useRecordContext(props);
    const resource = useResourceContext(props);
    const isSmallScreen = useIsSmallScreen();

    if (!record) return null;
    if (resource !== 'reclamations') throw new Error('PurchaseSeparatelyButton can only be used in Reclamations');

    const handleClose = () => setOpen(false);

    return (
        <>
            <IconButton
                label="app.action.purchase_separately"
                onClick={() => setOpen(true)}
                disabledReason={record.child ? 'app.message.alreadyBeenPurchasedSeparately' : null}
            >
                <StoreIcon />
            </IconButton>
            <MuiDialog open={open} onClose={handleClose} fullWidth fullScreen={isSmallScreen} maxWidth="md">
                <DialogContent onClose={handleClose} record={record} />
            </MuiDialog>
        </>
    );
};

const DialogContent = ({ onClose, ...props }: { onClose: () => void; record: Reclamation }) => {
    const { loading, purchaseReclamation, purchaseErrand, status } = usePurchaseData(props);

    const translate = useTranslate();
    const getResourceLabel = useGetResourceLabel();
    const getFieldLabel = useTranslateResourceField();
    const refresh = useRefresh();
    const notify = useNotify();
    const notifyError = useNotifyHttpError();
    const [create] = useCreate();

    const handleSubmit = async (data: { costRedeemedItem: number; store: Iri }) => {
        if (!status || !purchaseErrand) return;

        return create(
            'purchase_separately_sales',
            {
                status: status.id,
                purchaseReclamation: purchaseReclamation.id,
                cloneErrand: cloneErrand(purchaseErrand),
                cloneReclamation: cloneReclamation(purchaseReclamation),
                ...data,
            },
            {
                onSuccess: () => {
                    notify('ra.notification.created', {
                        type: 'info',
                        messageArgs: { smart_count: 1 },
                    });
                    onClose();
                    refresh();
                },
                onFailure: notifyError,
                returnPromise: true,
            },
        );
    };

    return (
        <>
            <DialogTitle>{translate('app.action.purchase_separately')}</DialogTitle>
            <RecordContextProvider value={{}}>
                <Form onSubmit={handleSubmit}>
                    {(formProps) => {
                        return (
                            <>
                                <MuiDialogContent>
                                    {loading ? (
                                        <LinearProgress />
                                    ) : !status || !purchaseErrand ? (
                                        <Alert severity="warning">
                                            {translate('app.label.missing_status', {
                                                status: getFieldLabel('purchasedSeparately', 'statuses'),
                                            })}
                                        </Alert>
                                    ) : (
                                        <form>
                                            <PriceInput
                                                label={getFieldLabel('costRedeemedItem', 'reclamations')}
                                                source="costRedeemedItem"
                                                validate={required()}
                                                fullWidth
                                            />
                                            <CurrencySelectInput source="currency" validate={required()} fullWidth />
                                            <HierarchicalAutocompleteSelectInput
                                                label={getResourceLabel('stores')}
                                                reference="stores"
                                                source="store"
                                                validate={required()}
                                                fullWidth
                                            />
                                        </form>
                                    )}
                                </MuiDialogContent>
                                <DialogActions>
                                    <Button onClick={onClose}>{translate('ra.action.cancel')}</Button>
                                    <LoadingButton
                                        label="ra.action.confirm"
                                        loading={formProps.submitting}
                                        icon={<StoreIcon />}
                                        onClick={formProps.handleSubmit}
                                        disabled={loading || formProps.pristine}
                                    />
                                </DialogActions>
                            </>
                        );
                    }}
                </Form>
            </RecordContextProvider>
        </>
    );
};

const usePurchaseData = ({ record: purchaseReclamation }: { record: Reclamation }) => {
    // Load errand
    const { data: errands, loaded: errandLoaded } = useGetMany('errands', [purchaseReclamation.errand]);
    // Load purchased separately status
    const { data: statuses, loaded: statusLoaded } = useGetList<Status>(
        'statuses',
        { page: 1, perPage: 1 },
        undefined,
        {
            purchasedSeparately: true,
        },
    );

    return {
        loading: !errandLoaded || !statusLoaded,
        status: Object.values(statuses)[0] as Status | undefined,
        purchaseErrand: errands?.[0] as Errand | undefined,
        purchaseReclamation,
    };
};

export default function PurchaseSeparatelyButton(props: Props) {
    const isFeatureDisabled = useIsFeatureDisabled();
    if (isFeatureDisabled('PurchaseSeparately')) return null;

    return <PurchaseSeparatelyButtonView {...props} />;
}
