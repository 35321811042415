import { SaleChannelType } from '@js/interfaces/SaleChannel';
import { useGetResourceLabel } from 'react-admin';

const useSaleChannelTypeChoices = () => {
    const getResourceLabel = useGetResourceLabel();

    return [
        { id: SaleChannelType.Sello, name: getResourceLabel('sello_sales') },
        { id: SaleChannelType.Imported, name: getResourceLabel('imported_sales') },
        { id: SaleChannelType.PurchaseSeparately, name: getResourceLabel('purchase_separately_sales') },
    ];
};

export default useSaleChannelTypeChoices;
